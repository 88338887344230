<template>
	<div class=" padding-top-80 padding-bottom-60">
		<top :bgWhite='true'></top>
		<div class="msg-content  padding-lr-30  top-80 padding-tb-20  bg-white ">
			<p class="fs16 bold margin-bottom-20">系统消息</p>
			<!-- 加载中 -->
			<div class="w100 " v-if="!isInit">
				<el-skeleton :rows="6" animated />
			</div>
			<div class="" v-else>
				<div class="lists " v-if="lists.length>0">
					<div class="list_item padding-bottom-10 margin-bottom-20" v-for="(item,index) in lists" :key="index">
						<div class="flex align-center justify-between">
							<p class="bold fs13">{{ item.title || '--' }}</p>
							<p class="fs9">{{ item.create_time }}</p>
						</div>
						<div class="margin-top-10">
							<p class="text-666 fs12" v-html="item.content ||'暂无消息内容'"></p>
						</div>
					</div>
				</div>
				<div class="" v-if="lists.length==0">
					<el-empty description="~空空如也~"></el-empty>
				</div>
			</div>
			<div class="flex align-center justify-center margin-top-30">
				<el-pagination background  layout="prev, pager, next" :total="totalSize" :page-size='15' :hide-on-single-page='false' @current-change="currentChange">
				</el-pagination>
			</div>
		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		components: {
			bottom,
			top
		},
		data(){
			return{
				isInit:false, //是否加载完毕
				totalSize:0, //总页数
				lists:[]
			}
		},
		mounted() {
			this.loadData()
		},
		methods:{
			// 点击当前页
			currentChange(e){
				this.page = e;
				this.loadData()
			},
			// 获取列表
			loadData() {
				this.ajax('post', '/v1/5cc56966e9287', {
					page:this.page
				}, res => {
					this.isInit = true;
					if (res.code == 1) {
						this.totalSize = res.data.total;
						this.lists =  res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
				})
				
			}
		}
	}
</script>

<style  lang="scss">
html,body{
	background: #F6F7F9 !important;
}
.msg-content{
	margin: 0 auto;
	width: 60%;
}
.contentAll{
	width: 60%;
	height: 100%;
}
.list_item{
	border-bottom: 1px solid #EBEEF5;
}
</style>